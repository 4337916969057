<template>
  <section>
    <b-loading v-if="!activityPasses.length"></b-loading>

    <div v-else class="container" :class="{ 'mt-6': isDesktopView }">
      <p class="mb-6 title">Create tickets</p>

      <b-field label="Pass">
        <b-select
          v-model="selectedPass"
          placeholder="Select a pass"
          required
          expanded
        >
          <option v-for="pass in activityPasses" :key="pass.id" :value="pass">
            {{ pass.title }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Number of tickets">
        <b-numberinput
          class="participants-field"
          type="is-primary"
          v-model="numberOfTickets"
          min="1"
          controls-alignment="right"
        ></b-numberinput>
      </b-field>

      <div class="mb-6">
        <b-field label="Payment type">
          <b-radio
            v-model="selectedPaymentOption"
            v-for="(option, index) in paymentOptions"
            :key="index"
            :name="option"
            :native-value="option"
          >
            {{ option }}
          </b-radio>
        </b-field>
      </div>

      <p class="section-subtitle mb-5">Buyer Info</p>

      <b-field label="Name" class="mt-4">
        <b-input required v-model="buyerName"></b-input>
      </b-field>

      <b-field label="Email" class="mb-5">
        <b-input required type="email" v-model="buyerEmail"></b-input>
      </b-field>

      <b-field label="Phone number (optional)" class="mb-4">
        <b-input v-model="buyerPhoneNumber"></b-input>
      </b-field>

      <b-button
        :disabled="!isFormValid"
        expanded
        rounded
        size="is-medium"
        type="is-primary"
        class="mt-6"
        @click="onFormSubmit"
        :loading="isCreatingTicket"
        >Generate {{ numberOfTickets > 1 ? "tickets" : "ticket" }}</b-button
      >

      <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
        <div class="card">
          <div class="card-content">
            <vue-lottie :options="lottieOptions" :width="204" :height="206" />
            <p
              class="modal-card-subtitle mb-5 mt-2 is-size-5 has-text-weight-semibold"
            >
              The
              {{ numberOfTickets > 1 ? "tickets were" : "ticket was" }}
              successfully created.
            </p>

            <div class="buttons column">
              <b-button
                type="is-success is-light"
                expanded
                @click.prevent="isCardModalActive = false"
              >
                Ok
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>
      <!--  <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
        <div class="card">
          <div class="card-content">
            <vue-lottie :options="lottieOptions" :width="204" :height="206" />
            <p
              class="modal-card-subtitle mb-5 mt-3 is-size-5 has-text-weight-semibold"
            >
              The
              {{ numberOfTickets > 1 ? "tickets were" : "ticket was" }}
              successfully created. Do you want to print the ticket now?
            </p>

            <b-message type="is-danger">
              <b>Warning:</b> Only print the ticket if the client wants to start
              the tour now.
            </b-message>

            <div class="buttons column">
              <b-button
                type="is-success is-light"
                expanded
                @click="printTicket"
              >
                Print {{ numberOfTickets > 1 ? "tickets" : "ticket" }}
              </b-button>
              <b-button
                type="is-light"
                expanded
                @click.prevent="isCardModalActive = false"
              >
                Close
              </b-button>
            </div>
          </div>
        </div>
      </b-modal> -->
    </div>
  </section>
</template>

<script>
import { ResellerRoles } from "../../enums/ResellerRoles";
import { mapMutations, mapState } from "vuex";
import {
  ActivitiesMutations,
  ActivitiesStates,
  UsersStates,
} from "../../store/Storetypes";
import { HelpersMixin } from "../../mixins";
import VueLottie from "vue-lottie";
const paymentOptions = ["Cash", "Credit Card"];

export default {
  mixins: [HelpersMixin],
  components: {
    VueLottie,
  },
  data() {
    return {
      numberOfTickets: 1,
      selectedPass: undefined,
      selectedPaymentOption: undefined,
      buyerName: "",
      buyerEmail: "",
      buyerPhoneNumber: "",
      paymentOptions,
      isCreatingTicket: false,
      isCardModalActive: false,
      lottieOptions: {
        animationData: require("@/assets/animations/success.json"),
        loop: false,
        autoplay: true,
      },
      ResellerRoles,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.users[UsersStates.USER],
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
    }),
    activityPasses() {
      return this.activities.filter((activity) => activity.isPass);
    },
    isFormValid() {
      const isValid =
        this.numberOfTickets !== 0 &&
        this.selectedPass &&
        this.selectedPaymentOption &&
        this.buyerName.trim().length &&
        this.buyerEmail.trim().length &&
        this.isValidEmail(this.buyerEmail);

      return isValid;
    },
  },
  methods: {
    ...mapMutations({
      getActivityData: ActivitiesMutations.GET_ACTIVITY_DATA,
    }),
    onFormSubmit() {
      this.isCreatingTicket = true;
      const {
        selectedPass,
        numberOfTickets,
        selectedPaymentOption,
        buyerEmail,
        buyerName,
        buyerPhoneNumber,
      } = this;
      const totalPrice = selectedPass.price * numberOfTickets;

      const metadata = {
        selectedPass: selectedPass.id,
        numberOfTickets,
        selectedPaymentOption,
        affiliate_id:
          this.user?.role === ResellerRoles.streetSeller
            ? this.user?.id
            : false, 
        dateOfPurchase: new Date(),
        participants: numberOfTickets,
        meetingPoint: "You can board at any of the route's stops.",
        totalPrice,
        buyerData: {
          name: buyerName,
          email: buyerEmail,
          phoneNumber: buyerPhoneNumber,
        },
      };

      if (this.user?.role !== ResellerRoles.hotel) {
        if (this.selectedPaymentOption === "Credit Card") {
          metadata.amountCard = totalPrice;
        } else {
          metadata.amountCash = totalPrice;
        }
        metadata.vendor_id = this.user?.id;
      }

      console.log(metadata);
     this.generateTicket(metadata);
      /*  if (this.user?.role !== ResellerRoles.hotel) {
        this.generateTicket(metadata);
        return;
      }
      this.generateCommissionedTicket(metadata); */
    },
    /*  async generateCommissionedTicket(metadata) {
      let data = {
        cancelUrl: `https://guiders.pt/vendor/generate-ticket`,
        priceLink: this.selectedPass.priceLink,
        quantity: this.numberOfTickets,
        successUrl: "https://guiders.pt/vendor/booking-success",
        metadata: {
          promoCode: "5iKM9g9G",
          selectedPass: this.selectedPass.id,
          isPass: true,
          dateOfPurchase: new Date(),
          expectedDateOfUse: undefined,
          buyerData: JSON.stringify({
            phoneNumber: metadata.buyerPhoneNumber,
            name: metadata.buyerData.name,
            email: metadata.buyerData.email,
          }),
          meetingPoint: this.selectedPass.meetingPoint,
          date: null,
          duration: this.selectedPass.duration,
          cancellationPolicy: this.selectedPass.cancellationPolicy,
          numberOfTickets: this.numberOfTickets,
          selectedPaymentOption: "Stripe",
          totalPrice: this.selectedPass?.price * this.numberOfTickets,
        },
      };
      await fetch(`${process.env.VUE_APP_API_URL}/stripe/pass/get`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log({ data });
          if (data.success) {
            window.location.href = data.message.url;
          } else {
            this.$buefy.toast.open({
              duration: 10000,
              message:
                data.message.raw.message ||
                "There has been an error booking your tour. If this error persists please contact support.",
              position: "is-bottom",
              type: "is-danger",
            });
          }
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "There has been an error booking your tour. If this error persists please contact support.",
            position: "is-bottom",
            type: "is-danger",
          });
        });
    }, */
    /*  addSaleToDb(metadata) {

      const data= {
        selectedPass,
        numberOfTickets,
        dateOfPurchase: new Date(),
       totalPrice: selectedPass.price * numberOfTickets,
        buyerData: {
          name: buyerName,
          email: buyerEmail,
          phoneNumber: buyerPhoneNumber,
        },
      } = metadata
      db.firestore()
        .collection("vendors")
        .doc(this.user.id)
        .update({
          salesHistory: FieldValue.arrayUnion(metadata),
        })
        .then(() => {
          this.isCardModalActive = true;
          this.numberOfTickets = 1;
          this.selectedPass = undefined;
          this.selectedPaymentOption = undefined;
          this.buyerName = "";
          this.buyerEmail = "";
          this.buyerPhoneNumber = "";
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "There has been an error adding this sale to the database. Try again later.",
            position: "is-bottom",
            type: "is-danger",
          });
        });

      this.isCreatingTicket = false;
    }, */
    async generateTicket(metadata) {
      await fetch(`${process.env.VUE_APP_API_URL}/activities/pass`, {
        method: "POST",
        body: JSON.stringify({ metadata: { ...metadata, isPass: true } }),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.isCardModalActive = true;
            this.numberOfTickets = 1;
            this.selectedPass = undefined;
            this.selectedPaymentOption = undefined;
            this.buyerName = "";
            this.buyerEmail = "";
            this.buyerPhoneNumber = "";
          } else {
            this.$buefy.toast.open({
              duration: 10000,
              message:
                data.message.raw.message ||
                "There has been an error creating the ticket(s). If this error persists please contact support.",
              position: "is-bottom",
              type: "is-danger",
            });
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "There has been an error creating the ticket(s). If this error persists please contact support.",
            position: "is-bottom",
            type: "is-danger",
          });
          console.log(error.message);
        });
      this.isCreatingTicket = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .card {
    margin: 0 0.5rem;
  }
  .modal-content {
    .button {
      font-size: 0.9rem;
    }
  }
  .participants-field .control {
    .button.is-primary[disabled],
    .button.is-primary {
      background-color: transparent;
      border-color: transparent;
      color: #0e3d4d;
    }
    .button.is-primary[disabled] {
      opacity: 0.1;
    }
  }
  .button.is-text:hover,
  .button.is-text.is-hovered,
  .button.is-text:focus,
  .button.is-text.is-focused {
    background-color: none;
  }
  .label {
    font-weight: 400;
    margin-bottom: 0.8rem !important;
  }
}

.field:not(:last-child) {
  margin-bottom: 1.75rem;
}

.section-subtitle,
.title {
  color: #0e3d4d;
  font-weight: 500;
}

.section-subtitle {
  font-size: 1.1rem;
}

.title {
  font-size: 1.25rem;
}
</style>
